<template>
  <div>
    <!-- Reward Ranks -->
    <div class="row">
      <div v-for="(t, i) in rewards" v-bind:key="i" v-bind:class="colsize(i)">
        <div class="card card-custom card-stretch gutter-b">
          <div class="card-body p-0">
            <div class="card-rounded p-8 bgi-no-repeat text-white body-height reward" style="background-position: right bottom; background-size: auto 100%;" :style="bg(t)">
              <h4 class="display-4 font-weight-bold">{{ t.title }}</h4>
              <div class="margin-sub">
                <div class="h5 font-weight-boldest">{{ t.desc1 }}</div>
                <div class="h5 font-weight-boldest">{{ t.desc2 }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "game_reward",
  components: {
  },
  data () {
    return {
      rewards: []
    }
  },
  methods: {
    colsize(t) {
      if (t === 0) {
        return 'col-12 rankfirst'
      }

      if (t === 1) {
        if (this.rewards.length % 2 === 1) {
          return 'col-6 ranksecond'
        } else {
          return 'col-12 ranksecond'
        }
      }

      return 'col-6 ranksecond'
    },
    recvData () {
      this.axios.get(`/tournaments/${this.tour}/reward`).then(
        ({ data }) => {
          this.rewards = data
        }
      )
    },
    bg(l) {
      var s = {}

      if (l.cbg != null && l.cbg !== '') {
        s['background-color'] = l.cbg
      }

      if (l.ctext != null && l.ctext !== '') {
        s['color'] = l.ctext
      }

      if (l.img != null && l.img !== '') {
        s['background-image'] = `url(${l.img})`
      }

      return s
    }
  },
  computed: {
    tour() {
      return this.$route.params.tour
    },
    game() {
      return this.$route.params.game
    }
  },
  mounted() {
    this.recvData()
  }
}
</script>

<style lang="scss" scoped>
.rankfirst {
  .body-height {
    height: 240px
  }

  .margin-sub {
    margin-top: 100px;
  }
}

.ranksecond {
  .body-height {
    height: 240px
  }

  .margin-sub {
    margin-top: 50px;
  }
}

.reward {
  background-color: #07c68c;
}
</style>>